import axiosWrapper from '../services/axiosWrapper';

export function setModalVisiblity(data) {
  return {
    type: 'SET_MODAL_VISIBLITY',
    data,
  };
}

export function getPortalDashboardObject(data) {
  return {
    type: 'GET_PORTAL_DASHBOARD_OBJECT',
    data,
  };
}

export function getPortalObject(data) {
  return (dispatch) => {
    axiosWrapper.get(`${process.env.REACT_APP_CUBE_BACKEND}/portal/getPortalDashboardObject`)
      .then((res) => {
        if (res.data.status) {
          dispatch(getPortalDashboardObject(res.data.data));
        }
      }).catch((err) => console.log('Error in portal dahboard object', err));
  };
}
