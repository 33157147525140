import notSupported from '@Assets/images/notSupported.svg';
import css from './index.module.css';

export default function OldBrowserWarning() {
    return (
        // css module is used because tailwind is not running in older browsers
        <div className={css.root}>
            <img src={notSupported} />
            <p>Please update your browser</p>
        </div>
    );
}
