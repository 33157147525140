const initialIssuesState = {};

export function issues(state = initialIssuesState, action) {
  switch (action.type) {
    case 'UPDATE_ISSUES_DATA':
      return action.data.reduce((prev, curr) => {
        if (curr && curr.id) {
          return {
             ...state,
            ...prev,
            [curr.id]: {
              ...state[curr.id],
              ...curr,
            },
          };
        }
        return { ...prev };
      }, {});

    case 'SET_ISSUES_DATA':
      return action.data.reduce((prev, curr) => {
        if (curr && curr.id) {
          return { ...prev, [curr.id]: curr };
        }
        return { ...prev };
      }, {});

    case 'MODIFY_ISSUES_DATA':
      return { ...state, [action.data.id]: { ...state[action.data.id], ...action.data } };

    case 'SET_ISSUES_COMMENTS_DATA':
      const issueData = state[action.issueId];
      let commentsData = {};
      if (Array.isArray(action.data)) {
        commentsData = action.data.reduce(
          (prev, curr) => ({ ...prev, [curr._id]: curr }),
          { },
        );
      } else if (typeof action.data === 'object') {
        if (issueData.comments) {
          commentsData = action.isEdit ? {
            ...issueData.comments,
            [action.data._id]: {
              ...issueData.comments[action.data._id],
              ...action.data,
            },
          } : {
            [action.data._id]: action.data,
            ...issueData.comments,
          };
        } else {
          commentsData = {
            [action.data._id]: {
              ...action.data,
            },
          };
        }
      }
      const upadatedData = { ...issueData, comments: commentsData };
      return { ...state, [action.issueId]: upadatedData };

    case 'DELETE_ISSUES_COMMENTS_DATA':
      const issue = state[action.issueId];
      delete issue.comments[action.data];
      return { ...state, [action.issueId]: issue };

    case 'EMPTY_ISSUES_DATA':
      return {};

    case 'MERGE_ISSUES_DATA':
      const issueId = action.data.id;
      if (action.editId) {
        return { ...state, [issueId]: { ...action.data } };
      }

      return { [issueId]: { ...action.data }, ...state };
      case 'REMOVE_ISSUE_FROM_STATE':
        const { ids } = action;
        const newState = { ...state };
        ids.forEach((id) => {
          delete newState[id];
        });

        return newState;

    case 'DELETE_ISSUES_DATA':
      const issues = action.data;
      const issuesState = { ...state };

      issues.forEach((issue) => delete issuesState[issue]);
      return issuesState;
    case 'INITIALIZE_ISSUES_DATA':
      return initialIssuesState;
    default:
      return state;
  }
}
