// convert to hook

import axios from 'axios';
import { setLicenceValidity } from '../actions/licence';
import { store } from '../App';

axios.interceptors.request.use(
  (config) => {
    const returnedConfig = {
      ...config,
      onUploadProgress: (progressEvent) => {
        if (config.onUploadProgress) {
          config.onUploadProgress(Math.floor((progressEvent.loaded * 100) / progressEvent.total), progressEvent.loaded);
        }
      },
    };
    returnedConfig.withCredentials = true;
    return returnedConfig;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status == '401' && !['/', '/googleLoginCallback', '/95789', '/signup', '/thank-you', '/pdfExport'].includes(window.location.pathname)) {
      localStorage.removeItem('isLogedIn');
      const errorMessage = error.response.data.errorMessage;

      const searchParams = new URLSearchParams();
      searchParams.set('redirectUrl', encodeURIComponent(window.location.pathname + window.location.search));
      if (errorMessage) {
        searchParams.set('errorMessage', encodeURIComponent(errorMessage));
      }

      const redirectUrl = `/?${searchParams.toString()}${window.location.hash}`;
      window.location.href = redirectUrl;
    } else if (error?.response?.status == '403' && error?.response?.data?.errorCode === 1001) {
      console.log('licence expired');
      store.dispatch(setLicenceValidity(false));
    }
    return Promise.reject(error);
  },
);

export default axios;
