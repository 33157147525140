const initialTasksStatusesState = {};

export function tasksStatuses(state = initialTasksStatusesState, action) {
  switch (action.type) {
    case 'SET_TASKS_STATUSES_DATA':
      return action.data.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr }),
        {},
      );
    default:
      return state;
  }
}
