export function currentSelectedSidebarCell(state = '', action) {
  switch (action.type) {
    case 'SET_SIDEBAR_SELECTED_CELL':
      return action.data;
    default:
      return state;
  }
}

export function showStickySidebar(state = '', action) {
  switch (action.type) {
    case 'SHOW_STICKY_SIDEBAR':
      return action.data;
    default:
      return state;
  }
}
