import { useEffect } from 'react';
import SubscriptionWarning from '../../components/SubscriptionWarning';

const SubscriptionWarningPage = () => {
  useEffect(() => {
    document.title = 'Subscription Plan List';
  }, []);

  const message = 'Your subscription has ended';
  const description = 'Please subscribe to the Pro plan regain access to the <Portal Name> portal';

  return <SubscriptionWarning message={message} description={description} />;
};

export default SubscriptionWarningPage;
