const initialProjectsDataState = {};

export function issueCount(state = initialProjectsDataState, action) {
  switch (action.type) {
    case 'SET_ISSUES_COUNT':
      return action.data;

    default:
      return state;
  }
}
