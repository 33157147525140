const initialFilesStatusesState = {};

export function filesStatuses(state = initialFilesStatusesState, action) {
  switch (action.type) {
    case 'SET_FILES_STATUSES_DATA':
      return action.data.reduce((prev, curr) => {
        if (curr.name.toLowerCase() == 'deleted') {
          return prev;
        }
        return { ...prev, [curr.id]: curr };
      }, {});
    default:
      return state;
  }
}

export function currentPath(state = null, action) {
  switch (action.type) {
    case 'SET_CURRENT_PATH':
      return action.data;
    default:
      return state;
  }
}
