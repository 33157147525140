import React, { FC } from "react";
// import "../../../assets/css/colors.css";
// import "../../../assets/css/size.css";

import { createUseStyles } from "react-jss";
import AvatarComponent from "../avatar";
import { UserTagComponentProps } from "../../interface";
import { GrClose } from 'react-icons/gr';
import { defaultUsernameTagComponentProps } from "../../constants/globalVariable";

const UsernameTagComponent: FC<UserTagComponentProps> = (props: UserTagComponentProps) => {
  /******************* 
@Purpose : UsernameTag Component
@Parameter : UsernameTagComponentProps
@Author : Techture
 *******************/

  const paddingRightName = props.showCrossIcon ? `var(--size-8)` : `var(--size-28)`
  const usernameBorderRadius = props.showCrossIcon ? `var(--size-0) var(--size-0) var(--size-0) var(--size-0)` : `var(--size-0) ${props.borderRadius} ${props.borderRadius} var(--size-0)`


  const useStyles = createUseStyles({
    usernameTagWrapper: {
      display: 'var(--display-inline-flex)',
      // alignItems: 'center',
    },
    userName: {
      border: 'var(--border-solid) var(--size-1) #ddebf3',
      color: "var(--primary-text)",
      borderRadius: usernameBorderRadius,
      background: props.userNameBackground,
      padding: `var(--size-1) ${paddingRightName} var(--size-0) var(--size-28)`,
      marginLeft: `${props.size === "XS" ? '-16px' : '-20px'}`,
      display: "var(--display-inline-flex)",
      alignItems: "center",
      whiteSpace: 'nowrap',
    },
    crossIcon: {
      cursor: 'pointer',
      border: 'var(--border-solid) var(--size-1) #ddebf3',
      borderLeft: 'none',
      color: "var(--primary-text)",
      borderRadius: `var(--size-0) ${props.borderRadius} ${props.borderRadius} var(--size-0)`,
      background: props.userNameBackground,
      padding: 'var(--size-1) var(--size-8) var(--size-0) var(--size-28)',
      marginLeft: `${props.size === "XS" ? '-8px' : '-10px'}`,
      display: "var(--display-inline-flex)",
      alignItems: "center"
    },
  });

  const classes = useStyles() as any;

  return (
    <div
      className={`${classes.usernameTagWrapper}`}
    >
      <AvatarComponent isCircle={props.isCircle} bgColor={props.iconBgColor} size={props.size} profilePicKey={props.profilePicKey} userName={props.userName} />
      <span className={classes.userName}>{props.userName}</span>
      {props.showCrossIcon &&
        <span className={classes.crossIcon}>
          <GrClose size={10} onClick={props.crossIconOnClick} />
        </span>
      }
    </div>
  );
};

UsernameTagComponent.defaultProps = defaultUsernameTagComponentProps
export default UsernameTagComponent;
