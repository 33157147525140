export function setSidebarSelectedCell(data) {
  return {
    type: 'SET_SIDEBAR_SELECTED_CELL',
    data,
  };
}
export function setShowStickySidebar(data) {
  return {
    type: 'SHOW_STICKY_SIDEBAR',
    data,
  };
}
