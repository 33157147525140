import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { convertDate } from '../../services/luxonHelper';
import './index.css';
import 'react-resizable/css/styles.css';
import 'react-grid-layout/css/styles.css';

import { BUTTON_TYPES } from '../../cubeComponent/constants/globalVariable';
import ButtonComponent from '../../cubeComponent/form/ButtonComponent';
import { THEME_LIGHT } from '../../App.constant';
import Tool from '../HoopsViewer/ToolbarViewer/Tool';
import { ReactComponent as searchIcon } from '../../assets/icons/svg/search-icon.svg';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../Search/useDebounce';
import { Predicate, Query } from '@syncfusion/ej2-data';
import { filesService, includedProjectsService } from '../../services/dataManager';
import ToolTipComponent from '../../cubeComponent/html/tooltip';
import DropDownListComponent from '../../cubeComponent/form/DropdownListComponent';
import UsernameTagComponent from '../../cubeComponent/html/usernameTag';
import { getNameInitials } from '../../cubeComponent/constants/globalFunctions';
import { ThemeContext } from '../../App';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
  getFileListPageUrl, getProjectPageUrl, getProjectDashboardPageUrl,
} from '../../services/utils/helpers';
import { getViewerUrl } from '@Helpers';
import { projectsViewType } from '../ProjectsGridView/ProjectsConstant';

const GlobalSearch = () => {
  const [autoComplete, setAutoComplete] = useState(false);
  const [projectsData, setProjectsData] = useState([]);
  const [filesData, setFilesData] = useState([]);
  const [selectedValue, setSelectedValue] = useState('Projects');
  const [inputValue, setInputValue] = useState(new Query());
  const [inputBoxValue, setInputBoxValue] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const themeContext = useContext(ThemeContext);
  const navigate = useNavigate();
  const inputBoxRef = useRef(null);

  const toProjectGridPath = () => {
    const projectGridPath = getProjectPageUrl(inputValue, projectsViewType.NORMAL_PROJECTS, projectsViewType.VIEW_GRID);
    navigate(projectGridPath);
  };

  const toFilesListPath = () => {
    const filesListPath = getFileListPageUrl(inputValue);
    navigate(filesListPath);
  };

  const toProjectDashboardPath = (projectId) => {
    const projectDashboardPath = getProjectDashboardPageUrl(projectId);
    navigate(projectDashboardPath);
  };

  const toDesignViewerPath = (fileVersionId, projectId, spaceId) => {
    const designViewerPath = getViewerUrl({ fileVersionId, projectId, spaceId });
    navigate(designViewerPath);
  };

  const viewAllData = (selectedValue) => {
    setAutoComplete(false);
    if (selectedValue == 'Projects') {
      toProjectGridPath();
    } else if (selectedValue == 'Files') {
      toFilesListPath();
    }
  };
  const onChangeInputBox = () => {
    setIsSearchActive(true);
    setProjectsData([]);
    setFilesData([]);
  };

  const autoCompleteCards = () => (
    <div className="p-4">
      {inputBoxValue && (
        <div className="">
          <DropDownListComponent
            buttonType={BUTTON_TYPES.DROPDOWN_GRADIENT_LIST}
            value={selectedValue}
            dataSource={[{ text: 'Projects', id: 'globalProjectSelectDropdown' }, { text: 'Files', id: 'globalFileSelectDropdown' }]}
            onChange={handleDropdownChange}
            width="100px"
          />
        </div>
      )}
      {(((selectedValue === 'Projects' && projectsData) || (selectedValue === 'Files' && filesData))?.slice(0, 3) || [])
        .map((record, index) => (
          <div key={`cardContainer_${index}`} className="py-2">
            <div
              className="border border-button_light rounded-lg"
              key={`cardComponent_${index}`}
              onClick={() => handleCardClick(index, selectedValue)}
            >
              <div className="flex flex-row w-full p-4 h-20">
                <div className="flex flex-col flex-1">
                  <ToolTipComponent content={record.description || record.fileName}>
                    <div className="#192048-600 font-semibold mb-1 overflow-hidden truncate w-72 whitespace-nowrap text-ellipsis text-base">
                      {record.description || record.fileName}
                    </div>

                  </ToolTipComponent>
                  <div className="#192048-600 mb-2 whitespace-nowrap overflow-ellipsis text-sm">
                    {record.projectId}
                  </div>

                </div>
                <div className="flex flex-row flex-1 space-x-4 items-center">
                  <div className="text-xs mb-2 flex flex-row items-center">
                    <span>
                      Created by
                    </span>
                    <div className="ml-2">
                      <UsernameTagComponent
                        profilePicKey={(selectedValue == 'Projects' ? record.profilePicId : record.createdBy.profilePic) ? `fileSystemServer/getImage/profile/${record.createdBy._id}` : null}
                        nameInitial={getNameInitials({
                          fullName: record.createdByName || record.createdBy.fullName,
                        })}
                        isCircle
                        size="XS"
                        userName={record.createdByName || record.createdBy.fullName}
                        borderRadius="var(--size-14)"
                        userNameBackground="#F0F5F9"
                        iconBgColor="var(--system-colour-2)"
                      />
                    </div>
                  </div>
                  <div className=" text-xs mb-2 flex flex-row">
                    on
                    <div className="ml-2">
                      {convertDate(record.createdDate, 'dateForText')}
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>
        ))}

      {(((selectedValue === 'Projects' && projectsData) || (selectedValue === 'Files' && filesData))?.length === 0) && (
        <div className="py-2">
          <div className="border border-button_light rounded-lg">
            <div className="flex flex-row w-full p-4">
              <div className="text-gray-500">No records found</div>
            </div>
          </div>
        </div>
      )}

      {(projectsData.length > 0 || filesData.length > 0) && (
        <ButtonComponent onClick={() => viewAllData(selectedValue)} buttonType={BUTTON_TYPES.BORDER_BUTTON}>
          View All
        </ButtonComponent>
      )}

    </div>
  );
  const handleProjectsFilter = async (value) => {
    const autoCompleteQuery = new Query()
      .where(new Predicate('projectId', 'contains', value)
        .or('description', 'contains', value)
        .and('archivedStatus', 'equal', 0))
      .take(3);

    if (value !== '') {
      setInputValue(value);
      fetchData(autoCompleteQuery);
    } else {
      setProjectsData([]);
    }
  };

  const handleFilesFilter = async (value) => {
    const autoCompleteQuery = new Query()
      .where(new Predicate('fileName', 'contains', value))
      .take(3);
    if (value !== '') {
      setInputValue(value);
      fetchData(autoCompleteQuery);
    } else {
      setFilesData([]);
    }
  };

  const handleTextInputFocus = () => {
    if (inputBoxRef.current) {
      inputBoxRef.current.focusIn();
    }
  };

  useEffect(() => {
    if (selectedValue == 'Projects') {
      handleProjectsFilter(inputBoxValue);
    } else {
      handleFilesFilter(inputBoxValue);
    }
  }, [selectedValue]);

  const handleClickOutside = (event) => {
    const isClickedOutside = !(
      event.target.closest('#globalSearchIconContainer')
      || event.target.closest('#globalProjectSelectDropdown')
      || event.target.closest('#globalFileSelectDropdown')
    );

    if (isClickedOutside && inputBoxRef.current) {
      inputBoxRef.current.value = '';
      setIsSearchActive(false);
      setAutoComplete(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const debouncedProjectsDataFilter = useDebounce((value) => {
    setInputBoxValue(value);
    if (selectedValue == 'Projects') {
      handleProjectsFilter(value);
    } else {
      handleFilesFilter(value);
    }
  }, 100);

  const fetchData = async (autoCompleteQuery) => {
    if (selectedValue == 'Projects') {
      const updatedData = await includedProjectsService.executeQuery(autoCompleteQuery);
      setProjectsData(updatedData.result);
    } else {
      const updatedData = await filesService.executeQuery(autoCompleteQuery);
      setFilesData(updatedData.result);
    }
  };

  const onInputChange = (e) => {
    setAutoComplete(true);
    debouncedProjectsDataFilter(e.value);
  };

  const handleCardClick = (index, selectedValue) => {
    if (selectedValue == 'Projects') {
      toProjectDashboardPath(projectsData[index].id);
    } else if (selectedValue == 'Files') {
      toDesignViewerPath(filesData[index]._id, filesData[index].file.space.project.id, filesData[index].file.space.id);
    }
  };

  const getTextBox = () => (
    <TextBoxComponent
      width="800px"
      type="text"
      placeholder="Search"
      data-testid="searchProject"
      showClearButton
      input={onInputChange}
      ref={inputBoxRef}
      created={handleTextInputFocus}
    />
  );

  return (
    <div id="globalSearchIconContainer" className="relative z-1000" data-testid="portalDashboardSearchContainer">
      <div
        className={isSearchActive ? 'hidden' : 'flex'}
        onClick={onChangeInputBox}
      >
        <Tool
          Svg={searchIcon}
          Tooltip="Global Search"
          position="BottomCenter"
          color={
            themeContext.theme === THEME_LIGHT
              ? 'var(--surface-black)'
              : 'var(--surface-white)'
          }
          setGradient={false}
        />
      </div>
        {isSearchActive && getTextBox()}
      {inputBoxValue && autoComplete && (
        <div className="absolute top-[calc(100% + 5px)] left-0 z-50 w-full max-h-[400px] border border-gray-300 bg-white">
          {autoCompleteCards()}
        </div>

      )}
    </div>
  );
};

export default GlobalSearch;
