export const workflowData = (state = {}, action) => {
  const { type, data } = action;

  if (type === 'SET_WORKFLOW_DATA') {
    return data;
  }

  return state;
};

export const workflowResourceType = (state = 'RESOURCE_TYPE_FILE', action) => {
  const { type, data } = action;

  if (type === 'SET_RESOURCE_TYPE_WORKFLOWS') {
    return data;
  }

  return state;
};

export const reviewData = (state = {}, action) => {
  const { type, data } = action;

  if (type === 'SET_REVIEW_DATA') {
    return data;
  }

  return state;
};
