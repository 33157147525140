export function projectUsers(state = [], action) {
  switch (action.type) {
    case 'SET_PROJECT_USER_DETAILS':
      return action.data;
    default:
      return state;
  }
}

export function spaceUsers(state = [], action) {
  switch (action.type) {
    case 'SET_SPACE_USER_DETAILS':
      return action.data;
    default:
      return state;
  }
}

export function UserInProject(state = [], action) {
  switch (action.type) {
    case 'PROJECT_USERS':
      return action.data;
    default:
      return state;
  }
}
