const initialIssuesStatusesState = {};

export function editIssues(state = initialIssuesStatusesState, action) {
  switch (action.type) {
    case 'SET_EDIT_ISSUES_DATA':
      return action.data;
    default:
      return state;
  }
}
