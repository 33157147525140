import axiosWrapper from '../services/axiosWrapper';

export function getEstimateList(data) {
  return {
    type: 'GET_ESTIMATE_LIST',
    data,
  };
}
export function getDateVersion(data) {
  return {
    type: 'GET_ESTIMATE_DATEVERSION',
    data,
  };
}

function getEstimateDataAction(data) {
  return {
    type: 'ESTIMATE_DATA',
    data,
  };
}

export function getEstimateData({ estimateId, versionId }) {
  return async (dispatch) => {
    const data = { _id: estimateId, versionId };
    const response = await axiosWrapper.post(`${process.env.REACT_APP_CUBE_BACKEND}/estimate/getEstimateData`, data);

    if (response.data.status) {
      const resData = response.data.result;
      dispatch(getEstimateDataAction(resData));
    }
  };
}

export async function getEstimateVersionsLatest({ estimateId }) {
  const response = await axiosWrapper.post(`${process.env.REACT_APP_CUBE_BACKEND}/estimate/getEstimateVersionsLatest`, { estimateId });
  return response.data.result;
}

export function getEstimateListData(data, skip, take) {
  return async (dispatch) => {
    data = { value: { params: { ...data }, skip, take } };
    const responseData = await axiosWrapper.post(`${process.env.REACT_APP_CUBE_BACKEND}/estimate/getEstimateList`, data);
    if (responseData.data.status) {
      const resData = responseData.data.result;
      dispatch(getEstimateList(resData));
    }
  };
}

export function emptyEstimateListData(data) {
  return (dispatch) => {
    dispatch({ type: 'EMPTY_ESTIMATE_LIST' });
  };
}
export function getEstimateDateVersion(data) {
  return async (dispatch) => {
    axiosWrapper.post(`${process.env.REACT_APP_CUBE_BACKEND}/estimate/getEstimateDateVersion`, data)
      .then((res) => {
        dispatch(getDateVersion(res.data.data));
      }).catch((err) => console.log('ERROR IN GETESTIMATEDATEVERSION ', err));
  };
}
