const initialUserDetailsDataState = {};

export function userDetails(state = initialUserDetailsDataState, action) {
  switch (action.type) {
    case 'SET_USER_DETAILS':
      return action.data;
    case 'UPDATE_USER_DETAILS':
      return { ...state, ...action.data };
    default:
      return state;
  }
}
