export function setMinimizerData(data) {
  return {
    type: 'SET_MINIMIZER_DATA',
    data,
  };
}

export function removeMinimizerData(data) {
  return {
    type: 'REMOVE_MINIMIZER_DATA',
    data,
  };
}

export function emptyMinimizerData() {
  return {
    type: 'EMPTY_MINIMIZER_DATA',
  };
}
