import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { registerLicense } from '@syncfusion/ej2-base';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

registerLicense(process.env.REACT_APP_LICENSE_KEY);

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
      maskAllText: false,
      blockAllMedia: false,
    })],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  // Sentry.configureScope((scope) => {
  //   scope.setLevel('warning');
  // });
}

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
