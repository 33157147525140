export const measurementsList = (state = [], { type, data }) => {
  switch (type) {
    case 'SET_MEASUREMENTS_DATA':
      return data;

    default:
      return state;
  }
};

export const takeoffsList = (state = [], { type, data }) => {
  switch (type) {
    case 'SET_TAKEOFFS_LIST':
      return data;

    default:
      return state;
  }
};

export const takeoffData = (state = {}, { type, data }) => {
  switch (type) {
    case 'SET_TAKEOFF':
      return data;

    default:
      return state;
  }
};

export const measurementsModalStatus = (state = { status: false }, { type, data }) => {
  switch (type) {
    case 'SET_MEASUREMENTS_MODAL_STATUS':
      return data;

    default:
      return state;
  }
};

export const isMeasurementStarted = (state = { status: false }, { type, data }) => {
  switch (type) {
    case 'SET_IS_MEASUREMENT_STARTED':
      return data;

    default:
      return state;
  }
};
