export function saveTransmital(state = [], action) {
  switch (action.type) {
    case 'SAVE_TRANSMITALS_DATA':
      return action.data;
    case 'MERGE_TRANSMITALS_DATA':
      return { ...state, ...action.data };
    default:
      return state;
  }
}
