import WarningImage from '../../assets/images/FutureModuleIllustrationOfEstimates.svg';

function MobileDeviceRestrict() {
  return (
    <div className="flex fixed h-full w-full z-10 flex-col justify-center bg-white text-center gap-4">
      <img src={WarningImage} />
      <span>CUBE is currently optimized for viewing on a Desktop or a Laptop. We are working on a mobile app to give you an on-the-go experience soon.</span>
    </div>
  );
}

export default MobileDeviceRestrict;
