export function resourceTypes(state = [], action) {
  switch (action.type) {
    case 'SET_TASK_RESOURCE_TYPE':
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
}

export function resources(state = [], action) {
  switch (action.type) {
    case 'SET_TASK_RESOURCE':
      return action.data;

    default:
      return state;
  }
}

export function resourceCreated(state = false, action) {
  switch (action.type) {
    case 'SET_RESOURCE_CREATED':
      return action.data;

    default:
      return state;
  }
}
