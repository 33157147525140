export function minimizeFloatterData(state = [], action) {
  switch (action.type) {
    case 'SET_MINIMIZER_DATA':
      const checkForExistingEntry = state.find((item) => item.path == action.data.path);
      if (!checkForExistingEntry) {
        const data = [...state, action.data];
        return data.map((item, index) => ({
          ...item,
          index,
        }));
      }
      return state;

    case 'REMOVE_MINIMIZER_DATA': {
      const data = [...state];
      data.splice(action.data, 1);
      const result = data.map((record, index) => ({ ...record, index }));
      return result;
    }

    case 'EMPTY_MINIMIZER_DATA':
      return [];

    default:
      return state;
  }
}
