export function saveDsrItem(state = [], action) {
  switch (action.type) {
    case 'SAVE_DSR_ITEM':
      return action.data;
    default:
      return state;
  }
}

export function getDsrItems(state = [], action) {
  switch (action.type) {
    case 'GET_DSR_ITEMS':
      return action.data;
    default:
      return state;
  }
}

export function costDatabase(state = {}, action) {
  const { type, data } = action;

  if (type === 'SET_COST_DATABASE') {
    return data;
  }

  return state;
}
