const initialPinDataState = {};

export function issuesPinData(state = initialPinDataState, action) {
  switch (action.type) {
    case 'SET_ISSUES_PIN_DATA':
      return action.data.reduce((prev, curr) => {
        if (curr && curr.id) {
          return { ...state, ...prev, [curr.id]: curr };
        }
        return { ...prev };
      }, {});
    case 'MERGE_ISSUES_PINDATA':
      const issueId = action.data.id;
      return { ...state, [issueId]: { ...action.data } };
    case 'DELETE_ISSUES_PINDATA':
      const issues = action.data;
      const issuesState = { ...state };
      issues.forEach((issue) => delete issuesState[issue]);
      return issuesState;
    default:
      return state;
  }
}
