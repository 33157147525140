const initialNotificationsStatus = {};

export function notificationsStatus(
  state = initialNotificationsStatus,
  action,
) {
  switch (action.type) {
    case 'SET_NOTIFICATIONS_STATUSES_DATA':
      return action.data.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: curr }),
        {},
      );
    default:
      return state;
  }
}

export function unreadNotificationsCount(state = 0, action) {
  switch (action.type) {
    case 'SET_UNREAD_NOTIFICATION_COUNT':
      return action.data;
    default:
      return state;
  }
}

export function showNotification(state = false, action) {
  switch (action.type) {
    case 'SET_SHOW_NOTIFICATION':
      return action.data;
    default:
      return state;
  }
}

export function notificationsData(state = [], action) {
  switch (action.type) {
    case 'SET_NOTIFICATION_DATA':
      return action.data;

    case 'READ_NOTIFICATION_DATA':
      return state.map((notification) => {
        if (action.data.indexOf(notification.id) !== -1) {
          notification.read = 'NOTIFICATION_READ';
        }
        return notification;
      });

    case 'UNREAD_NOTIFICATION_DATA':
      return state.map((notification) => {
        if (action.data.indexOf(notification.id) !== -1) {
          notification.read = 'NOTIFICATION_UNREAD';
        }
        return notification;
      });

    default:
      return state;
  }
}
