const initialOrgStructureData = {
  completeData: [],
};

export function orgStructureData(state = initialOrgStructureData, action) {
  switch (action.type) {
    case 'SET_ORGSTRUCTURE_DATA':
      return action.data;

    case 'ADD_ORGSTRUCTURE_DATA':
      return {
        ...state,
        completeData: [action.data, ...state.completeData],
      };

    case 'UPDATE_ORGSTRUCTURE_DATA':
      let updatedData = [...state.completeData];
      updatedData = updatedData.map((data) => {
        if (data.id === action.data.id) {
          return action.data;
        }
          return data;
      });
      return { ...state, completeData: updatedData };

    case 'REMOVE_ORGSTRUCTURE_DATA':
      let currentData = [...state.completeData];
      currentData = currentData.filter((data) => !action.data.includes(data.id)).map((data) => {
          if (action.data.includes(data.reportsTo)) {
            return {
              ...data,
              reportsTo: '',
              reportsToName: '',
            };
          }
          return data;
      });
      return {
        ...state,
        completeData: currentData,
      };
    case 'REORDER_ORGSTRUCTURE_DATA':
      let reorderedData = [...state.completeData];
      reorderedData = reorderedData.map((data) => {
        if (data.id === action.data.id) {
          return {
            ...data,
            parentId: action.data.parentId,
          };
        }
          return data;
      });
      return {
        ...state,
        completeData: reorderedData,
      };
    default:
      return state;
  }
}

export function projectAttachedPosition(state = [], action) {
  switch (action.type) {
    case 'SET_PROJECT_ATTACHED_POSITION':
      return action.data;
    default:
      return state;
  }
}
