// reducers/popupReducer.js
const initialState = {
    isBrowserPopupBlocked: false,
  };

const browserPopupReducer = (state = initialState, action) => {
switch (action.type) {
    case 'SET_POPUP_BLOCKED':
    return {
        ...state,
        isBrowserPopupBlocked: action.payload,
    };
    default:
    return state;
}
};

export default browserPopupReducer;
