import css from './index.module.css';
import ButtonComponent from '../../cubeComponent/form/ButtonComponent';

const SubscriptionWarning = ({
  message, description, showUpgradeButton, showFooterMessage,
}) => (
  <>
    <div className={css.root}>
      <img className="" src={`${process.env.REACT_APP_IMAGES_PUBLIC_URL}/logoAndText.png`} />
      <div className={css.body}>
        <div className={css.imageWrapper}>
          <img className={css.image} src={`${process.env.REACT_APP_IMAGES_PUBLIC_URL}/subscriptionWarning.svg`} />
          <div className={css.welcomeText}>
            <span className={css.welcomeHeader}>{message}</span>
            <span className={css.welcomeDescription}>{description}</span>
          </div>
        </div>
        {showUpgradeButton && (
          <div className={css.upgradeButton}>
            <ButtonComponent>Upgrade</ButtonComponent>
          </div>
        )}
      </div>
      {showFooterMessage && (
        <div className={css.footer}>
          Need support with increasing/decreasing your seats or have any questions related to pricing or billing? Raise a support ticket here.
        </div>
      )}
    </div>
  </>
);

export default SubscriptionWarning;
